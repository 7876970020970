.hero-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, var(--ai-surface) 20%, transparent 80%);
  padding: 80px 0;
  overflow: hidden;
  margin-top: 0;

  .custom-container {
    position: relative;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    z-index: 2;
  }

  .hero-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, var(--ai-surface) 20%, transparent 80%);
      z-index: 2;
    }

    .hero-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: saturate(1.2) contrast(1.1);
    }
  }

  .hero-content {
    max-width: 750px;
    padding: 3.5rem;
    background: rgba(255, 255, 255, 0.95);
    border-radius: var(--ai-radius-lg);
    box-shadow: var(--ai-shadow-lg);
    margin-left: 40px;
    position: relative;
    z-index: 3;

    .subtitle {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
      color: var(--ai-primary);
    }

    .title {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 1.5rem;
      color: var(--ai-text);
      white-space: normal;

      .text-gradient {
        background: var(--ai-gradient-primary);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .description {
      font-size: 1.35rem;
      line-height: 1.6;
      color: var(--ai-text-secondary);
      margin-bottom: 2rem;
    }

    .cta-button {
      padding: 1rem 2.5rem;
      font-size: 1.1rem;
      font-weight: 600;
      color: var(--ai-surface);
      background: var(--ai-gradient-primary);
      border: none;
      border-radius: var(--ai-radius-md);
      cursor: pointer;
      transition: var(--ai-transition);
      box-shadow: var(--ai-shadow-sm);

      &:hover {
        transform: translateY(-3px);
        box-shadow: var(--ai-shadow-lg);
      }
    }
  }
}

// Mobile styles
@media (max-width: 991px) {
  .hero-section {
    min-height: auto;
    padding: 60px 0;
    text-align: center;
    margin-top: 0;

    .hero-background {
      right: 0;
      width: 100%;
      height: 400px;
      opacity: 0.8;

      &::before {
        background: linear-gradient(to bottom, var(--ai-surface) 20%, transparent 80%);
      }
    }

    .hero-content {
      max-width: 100%;
      padding: 2rem;
      margin: 0 1rem;
      background: rgba(255, 255, 255, 0.98);

      .title {
        font-size: 2.8rem;
      }

      .description {
        font-size: 1.1rem;
      }
    }
  }
}
