// Common styles for all solution pages
.bg-gradient-primary {
  background: linear-gradient(135deg, #246bfd 0%, #1e4fd9 100%);
}

.service-card {
  height: 100%;
  transition: transform 0.3s ease;
  border: 1px solid #e5e5e5;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  h3 {
    color: #246bfd;
    margin-bottom: 1rem;
  }

  ul li {
    margin-bottom: 0.5rem;
    color: #444;
  }
}

.cta-section {
  padding: 3rem 0;
  background: #f8f9fa;
  border-radius: 10px;

  h2 {
    color: #246bfd;
    margin-bottom: 1rem;
  }

  .btn-primary {
    background: #246bfd;
    border-color: #246bfd;
    padding: 0.75rem 2rem;
    font-weight: 600;

    &:hover {
      background: #1e4fd9;
      border-color: #1e4fd9;
    }
  }
}

.page-banner {
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 1rem;
  }

  .lead {
    font-size: 1.25rem;
    opacity: 0.9;
  }
}
