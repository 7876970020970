// Scroll Reveal Animations
.reveal {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

// Stagger children animations
.stagger-children {
  > * {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i * 0.1}s;
      }
    }

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// Fade animations
.fade {
  &-up {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-down {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &-left {
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.visible {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &-right {
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.visible {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

// Scale animations
.scale {
  &-up {
    opacity: 0;
    transform: scale(0.95);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.visible {
      opacity: 1;
      transform: scale(1);
    }
  }

  &-down {
    opacity: 0;
    transform: scale(1.05);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.visible {
      opacity: 1;
      transform: scale(1);
    }
  }
}

// Hover animations
.hover {
  &-lift {
    transition: var(--ai-transition);

    &:hover {
      transform: translateY(-5px);
    }
  }

  &-scale {
    transition: var(--ai-transition);

    &:hover {
      transform: scale(1.02);
    }
  }

  &-glow {
    transition: var(--ai-transition);

    &:hover {
      box-shadow: 0 0 20px rgba(var(--ai-primary), 0.3);
    }
  }
}

// Loading animations
.loading {
  &-pulse {
    animation: pulse 1.5s ease-in-out infinite;
  }

  &-spin {
    animation: spin 1s linear infinite;
  }

  &-bounce {
    animation: bounce 1s ease infinite;
  }
}

// Keyframes
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

// Text animations
.text {
  &-gradient {
    background: var(--ai-gradient-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: textGradient 4s linear infinite;
  }

  &-typing {
    border-right: 2px solid var(--ai-primary);
    animation:
      typing 3.5s steps(40, end),
      blink-caret 0.75s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
  }
}

@keyframes textGradient {
  to {
    background-position: 200% center;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--ai-primary);
  }
}
