// AI Theme Variables
:root {
  // Primary Colors
  --ai-primary: #00a0f7;
  --ai-primary-dark: #0080c5;
  --ai-primary-light: #33b5ff;

  // Secondary Colors
  --ai-secondary: #6c63ff;
  --ai-secondary-dark: #534bff;
  --ai-secondary-light: #8a84ff;

  // Accent Colors
  --ai-accent: #00f5ff;
  --ai-accent-dark: #00d4e0;
  --ai-accent-light: #33f7ff;

  // Neutral Colors
  --ai-background: #f8f9fa;
  --ai-surface: #ffffff;
  --ai-text: #1a1a1a;
  --ai-text-light: #444444;

  // Gradients
  --ai-gradient-primary: linear-gradient(135deg, var(--ai-primary) 0%, var(--ai-secondary) 100%);
  --ai-gradient-accent: linear-gradient(135deg, var(--ai-accent) 0%, var(--ai-primary-light) 100%);

  // Shadows
  --ai-shadow-sm: 0 4px 6px rgba(0, 0, 0, 0.1);
  --ai-shadow-md: 0 8px 16px rgba(0, 0, 0, 0.1);
  --ai-shadow-lg: 0 16px 32px rgba(0, 0, 0, 0.1);

  // Border Radius
  --ai-radius-sm: 8px;
  --ai-radius-md: 12px;
  --ai-radius-lg: 20px;

  // Transitions
  --ai-transition: all 0.3s ease;

  // Spacing
  --ai-spacing-xs: 0.5rem;
  --ai-spacing-sm: 1rem;
  --ai-spacing-md: 2rem;
  --ai-spacing-lg: 4rem;
  --ai-spacing-xl: 8rem;
}

// Global AI Styles
body {
  background-color: var(--ai-background);
  color: var(--ai-text);
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  margin: 0;
  padding: 0;
}

// AI Card Styles
.ai-card {
  background: var(--ai-surface);
  border-radius: var(--ai-radius-md);
  box-shadow: var(--ai-shadow-md);
  transition: var(--ai-transition);
  overflow: hidden;

  &:hover {
    transform: translateY(-4px);
    box-shadow: var(--ai-shadow-lg);
  }
}

// AI Button Styles
.ai-button {
  background: var(--ai-gradient-primary);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: var(--ai-radius-sm);
  font-weight: 600;
  transition: var(--ai-transition);
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: var(--ai-shadow-md);
  }
}

// AI Section Styles
.ai-section {
  padding: var(--ai-spacing-lg) 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(36, 107, 253, 0.05) 0%, rgba(108, 99, 255, 0.05) 100%);
    z-index: 0;
  }
}

// AI Typography
.ai-title {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: var(--ai-spacing-md);
  background: var(--l
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ai-subtitle {
  font-size: 1.5rem;
  color: var(--ai-text-light);
  margin-bottom: var(--ai-spacing-lg);
}

// AI Grid Layout
.ai-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--ai-spacing-md);
  padding: var(--ai-spacing-md) 0;
}

// AI Animation Classes
.ai-fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: var(--ai-transition);

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.ai-slide-in {
  opacity: 0;
  transform: translateX(-20px);
  transition: var(--ai-transition);

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}

// AI Decorative Elements
.ai-decoration {
  position: absolute;
  pointer-events: none;
  z-index: 0;

  &.dots {
    background-image: radial-gradient(var(--ai-primary) 2px, transparent 2px);
    background-size: 20px 20px;
    opacity: 0.1;
  }

  &.lines {
    background-image: linear-gradient(90deg, var(--ai-primary) 1px, transparent 1px);
    background-size: 30px 1px;
    opacity: 0.1;
  }
}
